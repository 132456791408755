<template>
  <div>
    <template v-for="(bank, key) in bankAccount">
      <div :key="key" class="mb-4">
        <div class="ml-0">
          <div class="flex mb-4 items-center ml-1">
            <img :src="building" alt="building">
            <h4 class="lg:text-xl md:text-lg text-sm mb-0 ml-5">Bank account configuration {{ key + 1 }}</h4>
          </div>
          <hr class="line-hr w-full mb-8" />
        </div>
        <div class="onboarding-form banks">
          <div class="mb-12">
            <div class="grid grid-cols-12 gap-y-6 md:gap-y-0 md:gap-x-6 lg:gap-x-10">
              <div class="col-span-12 md:col-span-5 lg:col-span-4" v-if="bank.existingBankAccount">
                <vs-card class="bankCardSettlement shadow-none mb-0">
                  <div slot="header">
                    <div class="items-center">
                      <h5 class="font-normal text-lg md:text-xl text-body-gray">Settlement account</h5>
                    </div>
                  </div>
                  <div class="onboarding-form-settlement">
                    <div class="input-grp w-full mr-0">
                      <div class="">
                        <div class="text-base w-full p-0 mb-2 font-light">Same account as</div>
                        <p class="text-dark-blue font-normal">{{ bank.accountName }}</p>
                      </div>
                    </div>
                  </div>
                </vs-card>
              </div>
              <div class="col-span-12 md:col-span-5 lg:col-span-4" v-else>
                <vs-card class="bankCardSettlement shadow-none mb-0">
                  <div slot="header">
                    <div class="items-center">
                      <h5 class="font-normal text-lg md:text-xl text-body-gray">Settlement account</h5>
                    </div>
                  </div>
                  <div class="onboarding-form-settlement">
                    <div class="input-grp w-full mb-6 md:mb-10 mr-0">
                      <div class="">
                        <div class="text-base w-full p-0 mb-2 font-light">Account name</div>
                        <p class="text-dark-blue font-normal">{{ bank.accountName }}</p>
                      </div>
                    </div>

                    <div class="mb-6 md:mb-10">
                      <div class="grid grid-cols-7 gap-y-6 md:gap-y-0 sm:gap-x-8">
                        <div class="col-span-7 sm:col-span-3">
                          <div class="text-base w-full p-0 mb-2 font-light">BSB</div>
                          <p class="text-dark-blue font-light">{{ bank.bsb }}</p>
                        </div>

                        <div class="col-span-7 sm:col-span-4">
                          <div class="text-base w-full p-0 mb-2 font-light">Account number</div>
                          <p class="text-dark-blue font-light">{{ bank.accountNumber }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="input-grp w-full mr-0 mb-6 md:mb-6">
                      <div class="text-base pl-0  w-full mb-2 font-light">Bank statement</div>
                      <S3FileGenerator :document="bank.bankStatement"/>
                    </div>
                    <div class="input-grp w-full mr-0 mb-6 md:mb-6">
                      <div class="text-base pl-0 mb-2 w-full font-light">Which entity owns this bank account?</div>
                      <p class="text-dark-blue font-light">{{ getEntity(bank.entity) }}</p>
                    </div>
                  </div>
                </vs-card>
              </div>
              <div class="col-span-12 md:col-span-6 lg:col-span-6" v-if="bank.sameAsSettlementAccount || bank.existingBankAccountForBilling">
                <vs-card class="bankCardBilling shadow-none mb-0">
                  <div slot="header">
                    <div class="flex row justify-between">
                      <h5 class="font-normal text-lg md:text-xl text-body-gray">Billing account</h5>
                    </div>
                  </div>
                  <div class="onboarding-form-billing">
                    <div class="grid lg:grid-cols-12">
                      <div class="col-span-12 md:col-span-7">
                        <div class="input-grp w-full mr-0">
                          <div class="w-full">
                            <div class="text-base w-full p-0 mb-2 font-light" v-if="bank.sameAsSettlementAccount">Same as settlement account</div>
                            <div class="text-base w-full p-0 mb-2 font-light" v-if="bank.existingBankAccountForBilling">Same account as</div>
                            <p class="text-dark-blue font-normal">{{ bank.accountNameForBilling }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-card>
              </div>
              <div class="col-span-12 md:col-span-6 lg:col-span-6" v-else>
                <vs-card class="bankCardBilling shadow-none mb-0">
                  <div slot="header">
                    <div class="flex row justify-between">
                      <h5 class="font-normal text-lg md:text-xl text-body-gray">Billing account</h5>
                    </div>
                  </div>
                  <div class="onboarding-form-billing">
                    <div class="grid lg:grid-cols-12">
                      <div class="col-span-12 md:col-span-7">
                        <div class="input-grp w-full mb-6 md:mb-10 mr-0">
                          <div class="w-full">
                            <div class="text-base w-full p-0 mb-2 font-light">Account name</div>
                            <p class="text-dark-blue font-normal">{{ bank.accountNameForBilling }}</p>
                          </div>
                        </div>
                        <div class="mb-6 md:mb-10">
                          <div class="grid grid-cols-7 gap-y-6 md:gap-y-0 sm:gap-x-8">
                            <div class="col-span-7 sm:col-span-3"
                                 :class="bank.sameAsSettlementAccount ? 'readonly' : 'normal'">
                              <div class="text-base w-full p-0 mb-2 font-light">BSB</div>
                              <p class="text-dark-blue font-light">{{ bank.bsbForBilling }}</p>
                            </div>
                            <div class="col-span-7 sm:col-span-4">
                              <div class="text-base w-full p-0 mb-2 font-light">Account number</div>
                              <p class="text-dark-blue font-light">{{ bank.accountNumberForBilling }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="input-grp w-full mr-0 mb-6 md:mb-6">
                          <div class="text-base pl-0 mb-2 w-full font-light">Bank statement</div>
                          <S3FileGenerator :document="bank.bankStatementForBilling"/>
                        </div>
                      </div>
                      <div class="col-span-12">
                        <div class="input-grp w-full mr-0 mb-6 md:mb-6">
                          <div class="text-base pl-0 mb-2 w-full font-light">Which entity owns this bank account?</div>
                          <p class="text-dark-blue font-light">{{ getEntity(bank.entityForBilling) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import S3FileGenerator from "../../../components/S3FileGenerator";
const building = require("@/assets/images/building.svg");

export default {
  name:"BankAccount",
  components: {
    TheMask,
    S3FileGenerator
  },
  props: ["bankAccount", "entityList"],
  data(){
    return {
      building: building
    }
  },
  methods: {
    getEntity(id){
      if(!id) return "";
      let name = "";
      let objIndex = this.entityList.findIndex(
        (obj) => obj.entityId == id
      );
      if(objIndex >= 0){
        name = this.entityList[objIndex].entityType == 'Company' ? this.entityList[objIndex].companyName : this.entityList[objIndex].fullName;
      }
      return name;
    },
  }
};
</script>
