<template>
  <div class="onboarding-form">
    <div v-for="(doc, key) in payload" :key="key">
      <div class="flex">
        <div class="w-full">
          <label class="p-0 text-base text-grey font-light">{{ doc.title }}</label>
          <S3FileGenerator v-if="doc.filePath || doc.fileName" :document="isValidPath(doc.fileName) ? doc.fileName : doc.filePath" />
        </div>
        <div class="w-full">
          <label class="p-0 w-full text-grey text-base font-light">Audit note</label>
          <p class="text-base text-text-blue">{{ doc.note }}</p>
        </div>
      </div>
      <hr class="line-hr mt-2" v-if="payload[1]"/>
      <div class="mb-8">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import S3FileGenerator from "../../../components/S3FileGenerator"

const dictionary = {
  en: {
    attributes: {},
  },
};

export default {
  name: "SupportingDocument",
  components:{ S3FileGenerator },
  props: {
    onboardingRequestId: { type: String },
    document: { type: Array },
    applicationStatus: { type: String },
  },
  data() {
    return {
      payload: [],
      popupActivo: false,
      title: "",
      file: "",
      note: "",
      disableButton: true,
      isPdfUpload:true,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  methods: {
    isValidPath(path) {
      if (!path) {
        return false;
      }

      const pathSplit = path.split("/");

      if (pathSplit.length === 1) {
        return false;
      }

      return ["files", "images"].includes(pathSplit[0]);
    },
  },
  computed() {
    this.disableButton = this.file != "" && this.title != "" && this.note != "" ? false : true;
  },
  created() {
    this.payload = this.document;
  },
};
</script>
