<template>
  <div>
    <vs-row>
      <div class="mt-10 mb-5" v-for="(user, key) in users" :key="key">
        <h3>User {{ key + 1 }}</h3>
        <hr class="line-hr" />
        <div class="flex items-start mt-6">
          <div class="flex items-center justify-center">
            <div class="input-grp">
              <label class="text-base p-0 w-full text-dark-blue">Full name</label>
              <span>{{ user.fullName }}</span>
            </div>
            <div class="input-grp">
              <label class="text-base p-0 w-full text-dark-blue">Email address</label>
              <span>{{ user.email }}</span>
            </div>
            <div class="input-grp">
              <label class="text-base p-0 w-full text-dark-blue">Role</label>
              <span>{{ user.role }}</span>
            </div>
          </div>
        </div>
      </div>
    </vs-row>
  </div>
</template>
<script>

export default {
  props: {
    users: {
      type: Array
    }
  },
}
</script>

