<template>
  <div class="onboarding-form section-cms">
    <div class="pt-2 pb-3">
     <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full textBlack ">Authorised by:</label>
          <p class="mb-0">{{ terms.autorizedPerson.name }} - {{ terms.autorizedPerson.position }}</p>
        </div>
      </div>
      <hr class="line-hr my-4 lg:my-8 w-full" />
    </div>
    <div class="flex w-full items-start" v-if="legalsInfo">
      <div v-html="legalsInfo.explicitTerms"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OnboardingAgreement",
  props: {
    legalsInfo: { type: Object },
    terms: { type: Object },
  },
};
</script>